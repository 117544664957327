<script setup>
import ErrorModal from "~/components/ErrorModal.vue"
import Loader from '~/components/Loader.vue'
import '~/assets/scss/common.scss'
import { onMounted } from "vue";

useHead({
    link: [
        {
            rel: 'stylesheet',
            href: () => import('~/assets/scss/base.scss')
        }
    ]
})
</script>

<template>
    <div>
        <component :is="'style'">
            :root {
                --primary-color: #dd303a;
                --grey-color: #f7f7f9;
                --text-color: #14223d;
                --text-grey-color: #92a2c0;
                --border-grey-color: #d1d4dc;
            }
        </component>
        <div class="container">
            <slot />
            <ErrorModal />
            <Loader />
        </div>
    </div>
</template>